<template>
  <div id="wrapper" class="divided">

    <!-- One -->
    <section class="banner style1 orient-left content-align-left image-position-right fullscreen onload-image-fade-in onload-content-fade-right">
      <div class="content">
        <img src="/images/y_sante.png" class="service-name" alt="" />
        <p>
          Comment agir sur l’équilibre subtil de votre santé ?<br>
          Maladies, gestion de poids ou simplement envie de vous sentir mieux… La nourriture est notre source d’énergie !
          Nous étudions ensemble l’approche qui vous correspond le mieux, afin de trouver ou retrouver la qualité de vie que vous souhaitez !
        </p>

        <router-link class="button" to="/prices">
            Tarifs
        </router-link>
      </div>
      <div class="image">
        <img src="/images/sante/1.jpg" alt="" />
      </div>
    </section>

    <!-- Two -->
    <section class="spotlight style1 orient-right content-align-left image-position-center onscroll-image-fade-in" id="first">
      <div class="content">
        <h2>Accompagnement nutritionnel :</h2>
        <ul>
          <li>Troubles digestifs et symptômes associés (ballonnements, diarrhée, constipation, douleurs...)</li>
          <li>Problème de peau (eczéma, psoriasis,...)</li>
          <li>Gestion de poids et syndrome métabolique</li>
          <li>Diabète</li>
          <li>Stress et fatigue chronique</li>
          <li>Perte de vitalité</li>
          <li>Maladies neurologiques et dégénératives (sclérose en plaque, Parkinson, dépression, trouble de l’humeur)</li>
          <li>Maladies auto-immunes (maladie de Crohn, RCH, polyarthrite rhumatoïde diabète de type 1)</li>
          <li>Enfants : TDAH, troubles «dys», fatigue chronique, eczéma, gestion de poids...</li>
        </ul>
      </div>
      <div class="image">
        <img src="/images/sante/2.jpg" alt="" />
      </div>
    </section>
  </div>
</template>
